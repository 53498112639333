.ticker {
  display: flex;
  background-color: #c0c0c0;
  width: 100%;
  overflow: hidden;
  flex: 0 0;
  background-image: linear-gradient(90deg, #0bd3d3, #f890e7);
}

.ticker-list {
  display: flex;
  flex: 0 0;
  animation: ticker 50s infinite linear;
}

.ticker:hover .ticker-list {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.tickerItem {
  border-left: 1px solid transparent;
  display: table-cell;
  position: relative;
  vertical-align: top;
  color: #fff;
}

.tickerItem:hover {
  background-color: #000;
}

.tickerItem:after {
  background: #2a2e39;
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: -1px;
  width: 1px;
}

.switchboard-ticker {
  position: sticky;
  top: 0px;
  z-index: 9999;
}
