@font-face {
  font-family: "MiamiFont";
  font-style: normal;
  font-weight: normal;
  src: url("./static/fonts/MiamiVice.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: linear-gradient(90deg, #0bd3d3, #f890e7);
}
.switchboard-cards::-webkit-scrollbar {
  width: 0.8em;
}

.switchboard-cards::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.switchboard-cards::-webkit-scrollbar-thumb {
  background-color: rgba(248, 144, 231, 0.7);
  outline: 1px solid slategrey;
}

.title {
  font-family: MiamiFont;
  font-style: normal;
  font-size: 3em;
  color: "#f890e7";
  margin: auto;
  margin-bottom: 0;
}

.selected-feed {
  font-family: MiamiFont;
  font-style: normal;
  font-size: 5em;
  margin: auto;
  margin-top: 0;
  text-align: center;
}

.miami {
  font-family: MiamiFont;
  font-style: normal;
}

.solana-switch {
  font-family: MiamiFont;
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  margin-top: 0;
  color: "#f890e7";
}
